<template>
  <div class="gsp">
    <section class="gsp__banner">
      <div class="container">
        <div class="gsp__banner-content">
          <h1 class="gsp__banner-title">{{ $t("GSP") }}</h1>
          <p class="gsp__banner-text">
            {{ $t("low_income") }}
          </p>
          <!-- <button class="gsp__banner-btn" @click="isShowModal = true">
            {{ $t("findIIN") }}
          </button> -->
        </div>
      </div>
    </section>
    <!-- <section class="gsp__life">
      <div class="container">
        <h3 class="gsp__life-title">{{ $t("fillLife") }}</h3>
        <div class="gsp__life-content">
          <p class="gsp__life-text">
            {{ $t("text_1") }}
          </p>
          <img src="@/assets/img/gsp/gsp-magnum.svg" alt="" />
        </div>
        <div v-if="isShowMoreMobile" class="gsp__life-text--more">
          <p class="gsp__life-text">
            {{ $t("text_2") }}
          </p>
          <p class="gsp__life-text">{{ $t("text_3") }}</p>
          <p class="gsp__life-text">
            {{ $t("text_4") }}
          </p>
          <p class="gsp__life-text">
            {{ $t("text_4-5") }}
          </p>
        </div>
        <div v-if="isShowMore" class="gsp__life-text--more">
          <p class="gsp__life-text gsp__life-text--more">
            {{ $t("text_5") }}
          </p>
          <p class="gsp__life-text gsp__life-text--more">
            {{ $t("text_6") }}
          </p>
          <p
            class="gsp__life-text gsp__life-text--more"
            v-html="$t('text_7')"
          ></p>
          <p class="gsp__life-text gsp__life-text--more">
            {{ $t("text_8") }}
          </p>
          <p class="gsp__life-text gsp__life-text--more">
            {{ $t("text_9") }}
          </p>
          <p class="gsp__life-text gsp__life-text--more">
            {{ $t("text_10") }}
          </p>
        </div>

        <button class="gsp__life-btn" @click="readMore">
          {{ isShowMore ? "Свернуть" : "Читать больше"
          }}<img
            class="gsp__life-arrow"
            :class="{ active: isShowMore }"
            src="@/assets/img/gsp/gsp-arrow.svg"
            alt=""
          />
        </button>
      </div>
    </section> 
  <section class="gsp__content">
      <div class="container">
        <h3 class="gsp__content-title">
          {{ $t("contentGSP") }}
        </h3>
        <div class="gsp__card">
          <h5 class="gsp__card-title">{{ $t("monthly") }}</h5>
          <div class="gsp__card-box">
            <div class="gsp__card-block">
              <h6 class="gsp__card-subtitle green">
                3-6 {{ $t("contentYears") }}
              </h6>
              <ul class="gsp__card-list">
                <li
                  v-for="(item, index) in $t('gspList.monthly_3-6')"
                  :key="index"
                  class="gsp__card-item"
                >
                  <p class="gsp__card-left">
                    {{ item.title }}
                  </p>
                  <p class="gsp__card-rigth">
                    {{ item.volume }} {{ $t("thing") }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="gsp__card-block">
              <h6 class="gsp__card-subtitle blue">
                1-3 {{ $t("contentYears") }}
              </h6>
              <ul class="gsp__card-list">
                <li
                  v-for="(item, index) in $t('gspList.monthly_1-3')"
                  :key="index"
                  class="gsp__card-item"
                >
                  <p class="gsp__card-left">
                    {{ item.title }}
                  </p>
                  <p class="gsp__card-rigth">
                    {{ item.volume }} {{ $t("thing") }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="gsp__card">
          <h5 class="gsp__card-title">{{ $t("quarterly") }}</h5>
          <div class="gsp__card-box">
            <div class="gsp__card-block">
              <h6 class="gsp__card-subtitle violet">
                1-6 {{ $t("contentYears") }}
              </h6>
              <ul class="gsp__card-list">
                <li
                  v-for="(item, index) in $t('gspList.quarterly')"
                  :key="index"
                  class="gsp__card-item"
                >
                  <p class="gsp__card-left">
                    {{ item.title }}
                  </p>
                  <p class="gsp__card-rigth">
                    {{ item.volume }} {{ $t("thing") }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gsp__IIN">
      <div class="container">
        <div class="gsp__IIN-content">
          <h3 class="gsp__IIN-title">{{ $t("findYourIIN") }}</h3>
          <p class="gsp__IIN-text">
            {{ $t("findYourIIN_text") }}
          </p>
          <button class="gsp__IIN-btn" @click="isShowModal = true">
            {{ $t("foundIIN") }}
          </button>
        </div>
      </div>
    </section>
    <app-modal-gsp v-if="isShowModal" @close="isShowModal = false" /> -->
  </div>
</template>

<script>
export default {
  name: "MagnumGsp",
  components: {
    // AppModalGsp: () => import("@/components/gsp/AppModalGsp.vue"),
  },
  data() {
    return {
      isShowMore: false,
      isShowModal: false,
      readMoreBtn: "Читать больше",
      isShowMoreMobile: true,
    };
  },
  mounted() {
    if (this.$isMobile()) {
      this.isShowMoreMobile = false;
    }
  },
  methods: {
    readMore() {
      if (this.$isMobile()) {
        this.isShowMore = !this.isShowMore;
        this.isShowMoreMobile = !this.isShowMoreMobile;
      } else {
        this.isShowMore = !this.isShowMore;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
